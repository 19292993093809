<template>
    <div>
        <v-skeleton-loader type="list-item-three-line" style="height:100%;" :loading="!loaded" transition="fade-transition">
            <v-card flat>
                <v-card-title>
                    Amenities
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="amenitiesDialog = true" style="text-transform:none" color="primary">
                        <v-icon left>mdi-plus</v-icon>
                        Add new
                    </v-btn>
                </v-card-title>
                <v-card-subtitle>
                    This are all the amenities your community provide
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col xs="12" sm="12" md="12" lg="6" xl="6" offset-lg="6" offset-xl="6">
                            <v-text-field v-model="filterAmenities" append-icon="mdi-magnify" label="Filter" clearable single-line hide-details></v-text-field>
                        </v-col>
                    </v-row>
                    <v-data-table :headers="headers" :items="amenities" :search="filterAmenities" :items-per-page="25">
                        <template v-slot:item.imageSrc="{ item }">
                            <v-avatar size="35">
                                <img :src="item.gallery[0].url.small" v-if="item.gallery.length > 0 && item.gallery[0].url != null && item.gallery[0].url.small.length > 0">
                                <v-icon v-else>mdi-balloon</v-icon>
                            </v-avatar>
                        </template>
                        <template v-slot:item.active="{ item }">
                            {{ item.active == 1 ? 'Yes' : 'No' }}
                        </template>
                        <template v-slot:item.dateAdded="{ item }">
                            {{ formatDate( item.dateAdded, 'MMM DD, YYYY' ) }}
                        </template>
                        <template v-slot:item.more="{ item }">
                            <v-icon @click="editAmenity(item)">mdi-pencil</v-icon>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-skeleton-loader>

        <amenity ref="amenity" :dialog="true" :dialogTitle="amenityDialogTitle" :dialogSubtitle="amenityDialogSubtitle" :dialogOpen="amenitiesDialog" 
                    @cancel="amenitiesDialog = false" @saved="addAmenity2Table" @update="updateAmenity"></amenity>
    </div>
</template>

<script>
import { API } from '@/inc/api';
import Amenity from '@/components/Amenity.vue';

export default {
    data: () => ({
        headers: [
            { text: 'Avatar', value: 'imageSrc', sortable: false },
            { text: 'Name', value: 'name', sortable: true },
            { text: 'Short Description', value: 'previewDescription', sortable: true },
            { text: 'Active', value: 'active', sortable: true },
            { text: 'Date Created', value: 'dateAdded', sortable: true },
            { text: 'Actions', value: 'more', sortable: false },
        ],
        amenities: [],
        loaded: false,
        filterAmenities: '',
        amenitiesDialog: false,
        amenityDialogTitle: 'New Amenity',
        amenityDialogSubtitle: 'Add new amenity to your community',
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let api = new API();

            try {
                this.loaded = false;
                let res = await api.get('/admin/amenities');
                this.loaded = true;

                if( !res.data.status && res.data.error ) {
                    console.log('ERROR: ' + res.data.message );
                    return;
                }
                
                this.amenities = res.data.data;
            }
            catch(error) {
                this.loaded = true;
                console.log(error);
            }
        },
        addAmenity2Table( amenity ) {
            let found = this.amenities.find(r => {
                return r.id == amenity.id;
            });

            if( found != undefined && found != null ) 
                return; // is an update

            this.amenities.push(amenity);
        },
        updateAmenity( amenity ) {
            let found = this.amenities.find(r => {
                return r.id == amenity.id;
            });
            
            if( found == undefined || found == null ) 
                return; // is not an update

            found.name = amenity.name;
            found.previewDescription = amenity.previewDescription;
            found.description = amenity.description;
            found.active = amenity.active;
            found.imageSrc = amenity.imageSrc;
            found.imageLazySrc = amenity.imageLazySrc;
            found.gallery = amenity.gallery;
            found.rules = amenity.rules;
        },
        editAmenity( amenity ) {
            this.$refs.amenity.load( amenity );
            this.amenitiesDialog = true;
        },
    },
    components: {
        Amenity
    }
}
</script>

<style scoped>

</style>