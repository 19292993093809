var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-skeleton-loader',{staticStyle:{"height":"100%"},attrs:{"type":"list-item-three-line","loading":!_vm.loaded,"transition":"fade-transition"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Amenities "),_c('v-spacer'),_c('v-btn',{staticStyle:{"text-transform":"none"},attrs:{"depressed":"","color":"primary"},on:{"click":function($event){_vm.amenitiesDialog = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add new ")],1)],1),_c('v-card-subtitle',[_vm._v(" This are all the amenities your community provide ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"6","xl":"6","offset-lg":"6","offset-xl":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Filter","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.filterAmenities),callback:function ($$v) {_vm.filterAmenities=$$v},expression:"filterAmenities"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.amenities,"search":_vm.filterAmenities,"items-per-page":25},scopedSlots:_vm._u([{key:"item.imageSrc",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"35"}},[(item.gallery.length > 0 && item.gallery[0].url != null && item.gallery[0].url.small.length > 0)?_c('img',{attrs:{"src":item.gallery[0].url.small}}):_c('v-icon',[_vm._v("mdi-balloon")])],1)]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.active == 1 ? 'Yes' : 'No')+" ")]}},{key:"item.dateAdded",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate( item.dateAdded, 'MMM DD, YYYY' ))+" ")]}},{key:"item.more",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.editAmenity(item)}}},[_vm._v("mdi-pencil")])]}}])})],1)],1)],1),_c('amenity',{ref:"amenity",attrs:{"dialog":true,"dialogTitle":_vm.amenityDialogTitle,"dialogSubtitle":_vm.amenityDialogSubtitle,"dialogOpen":_vm.amenitiesDialog},on:{"cancel":function($event){_vm.amenitiesDialog = false},"saved":_vm.addAmenity2Table,"update":_vm.updateAmenity}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }